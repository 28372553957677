/* eslint-env jquery */

"use strict";

const populateCarouselTabHeaders = ($tabHeader, $carouselContainer) => {
    $tabHeader.empty();

    const $carousels = $carouselContainer.find(".js-swiper-products-carousel, .js-einstein-carousel");

    $carousels.each(function () {
        const $carousel = $(this);
        const isEinsteinCarousel = $carousel.hasClass("js-einstein-carousel");
        const $tabHeaderItem = $(
            `<li class="tab-carousels__tabs-tab js-tab-item font-medium"
            data-carousel-id="${$carousel.data("id")}">${isEinsteinCarousel ? $carousel.data("carousel-name") : $carousel.data("name")}</li>`
        );

        $tabHeader.append($tabHeaderItem);
    });
};

const setupTabCarousel = ($tabCarousel) => {
    const $tabsContent = $tabCarousel.find(".js-tab-content");
    const $tabCarousels = $tabsContent.find(".js-swiper-products-carousel, .js-einstein-carousel");
    const $tabHeaderContainer = $tabCarousel.find(".js-tabs");

    //Take all the carousels, and populate the tab headers by using the name for each one.
    populateCarouselTabHeaders($tabHeaderContainer, $tabsContent);

    //Take all the populated tab headers into a list for later user, this need to be called after the populateCarouselTabHeaders function.
    const $tabHeaders = $tabHeaderContainer.find(".js-tab-item");

    //On click on a tab header, show the associated tab
    $tabHeaderContainer.on("click", ".js-tab-item", function () {
        const $tabItem = $(this);
        const carouselId = $tabItem.data("carousel-id");
        let $selectedCarousel = $tabsContent.find(`.js-swiper-products-carousel[data-id="${carouselId}"]`);

        if ($selectedCarousel.length === 0) {
            $selectedCarousel = $tabsContent.find(`.js-einstein-carousel[data-id="${carouselId}"]`);
        }

        //Hide all carousels and inactive all headers
        $tabCarousels.hide();
        $tabHeaders.removeClass("active");
        //Show the selected carousel and activate the selected header
        $selectedCarousel.show();
        $tabItem.addClass("active");
    });

    //On startup, only make the first carousel visible, and make the associated tab header active.
    $tabCarousels.hide();
    $tabCarousels.first().show();
    $tabHeaders.first().addClass("active");
};

$(window).on("load", () => {
    const $tabCarousels = $(".js-tab-carousels");

    $tabCarousels.each(function () {
        const $tabCarousel = $(this);
        setupTabCarousel($tabCarousel);
    });
});
